<template>
  <div>
    <router-view :folder="folder" :page="page" :perPage="perPage" @openPage="$emit('openPage', $event)" @updateMessages="$emit('updateMessages')"/>
  </div>
</template>

<script>

export default {
  props: {
    folder: {
      type: Object,
    },
    page: Number,
    perPage: Number,
  },
  name: "MailPage"
}
</script>

<style scoped lang="scss">

</style>
